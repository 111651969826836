import '/assets/styles/pages/login.scss';
import { useEffect } from 'react';

// Partials
import Btn from '/views/partials/btn';
import Loader from '/views/partials/loader';

// Context
import { useModals } from 'pstv-commerce-tools/utilities/modals';
import { useAuth } from 'pstv-commerce-tools/utilities/auth';
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'

export const Login = () => {
	const { openModal } = useModals();
	const { loggedIn, initialized } = useAuth();
	const { redirect } = useNavigator();

	useEffect(() => {
		if(initialized && loggedIn) {
			redirect('account.info', {}, { replace: true });
		}
	}, [initialized, loggedIn])

	if(initialized && !loggedIn) {
		return (
			<div className="wrapper">
				<div className="login-message">
					<h1 className="message-title">Bu sayfaya erişmek için giriş yapmalısınız.</h1>
	
					<Btn 
						className="primary"
						onClick={() => { openModal('auth'); }}>
						Giriş Yap
					</Btn>
				</div>
			</div>
		)
	}
	else {
		return <Loader />
	}
}

export default Login
